import {defineAsyncComponent} from 'vue';
import vueComponentsLoader from '@/client/components-loader';
import {initFormEvents} from '@/client/listeners/form-events';

// Dynamic styles import
import('@styles/scss/client.scss');

// Define Frontend components
const vueComponents = {
    'weberhause.inspirations': defineAsyncComponent(
        () => import('@/client/page/weberhause/Inspirations.vue')
    ),
};

// Components Loader
document.addEventListener('DOMContentLoaded', () => {
    vueComponentsLoader.init(vueComponents);
    initFormEvents();
});