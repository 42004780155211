export default {
    beforeMount(el) {
        el.addEventListener('input', function () {
            const inputValue = el.value.replace(/[^0-9]/g, '');
            if (el.value !== inputValue) {
                el.value = inputValue;
                el.dispatchEvent(new Event('input'));
            }
        });
    }
};