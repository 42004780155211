const dragAndDropInputFields = () => {
    
    function init() {
        const fileDropZoneEl = document.querySelector('._swm-file-dropzone');
        const fileInputEl = fileDropZoneEl.querySelector('input[type="file"]');
        
        fileDropZoneEl.addEventListener('click', handleDropZoneClick);
        fileDropZoneEl.addEventListener('dragover', handleDropZoneDragOver);
        fileDropZoneEl.addEventListener('dragleave', handleDropZoneDragLeave);
        fileDropZoneEl.addEventListener('drop', handleDropFile);
        fileInputEl.addEventListener('change', handleChangeFile);
    }
    
    function handleDropZoneClick(event) {
        const fileInputEl = event.target.querySelector('input[type="file"]');
        if (fileInputEl) {
            fileInputEl.click();
        }
    }
    
    function handleDropZoneDragOver(event) {
        event.preventDefault();
        event.target.classList.add('dragover');
    }
    
    function handleDropZoneDragLeave(event) {
        event.preventDefault();
        event.target.classList.remove('dragover');
    }
    
    function handleDropFile(event) {
        event.preventDefault();
        event.target.classList.remove('dragover');
        event.target.files = event.dataTransfer.files;
        
        updateInputValue(event);
    }
    
    function convertFileSize(fileSize) {
        if (!fileSize) {
            return null;
        }
        
        const size = parseFloat(fileSize);
        if (isNaN(size)) {
            return null;
        }
        
        const unit = typeof fileSize === 'string'
            ? fileSize.replace(/[0-9.]/g, '').trim().toUpperCase()
            : '';
        
        switch (unit) {
            case 'MB':
                return size * 1024 * 1024;
            case 'KB':
                return size * 1024;
            case 'B':
                return size;
            default:
                return size;
        }
    }
    
    function handleChangeFile(event) {
        const dataTransfer = new DataTransfer();
        const filesEl = event.target;
        const dropZoneEl = filesEl.closest('._swm-file-dropzone');
        const files = filesEl.files;
        
        dropZoneEl.classList.remove('error');
        dropZoneEl.querySelector('.error.file-accept-error').classList.remove('show');
        dropZoneEl.querySelector('.error.file-size-error').classList.remove('show');
        
        const acceptedFilesTypes = filesEl.dataset?.accept || null;
        const haveNotAcceptedFiles = acceptedFilesTypes && [...files].some(file => !acceptedFilesTypes.includes(file.type));
        if (haveNotAcceptedFiles) {
            dropZoneEl.classList.add('error');
            dropZoneEl.querySelector('.error.file-accept-error').classList.add('show');

            filesEl.files = dataTransfer.files;
            filesEl.value = '';
            return;
        }
        
        let maxFileSize = filesEl.dataset?.max_size || null;
        maxFileSize = convertFileSize(maxFileSize);
        
        const haveFilesWithBigSize = maxFileSize && [...files].some(file => file.size > maxFileSize);
        if (haveFilesWithBigSize) {
            dropZoneEl.classList.add('error');
            dropZoneEl.querySelector('.error.file-size-error').classList.add('show');
            
            filesEl.files = dataTransfer.files;
            filesEl.value = '';
            return;
        }
        
        updateFilesList(event);
    }
    
    function updateFilesList(event) {
        const files = event.target.files;
        const filesListEl = event.target.closest('._swm-file-dropzone').querySelector('.files-list');
        
        if (filesListEl) {
            filesListEl.innerHTML = '';
            [...files].forEach(file => {
                const fileEl = document.createElement('div');
                fileEl.innerText = file.name;
                filesListEl.appendChild(fileEl);
            });
        }
    }
    
    function updateInputValue(event) {
        const dataTransfer = new DataTransfer();
        const files = event.target.files;
        
        [...files].forEach(file => dataTransfer.items.add(file));
        
        const inputEl = event.target.querySelector('input[type="file"]');
        inputEl.files = dataTransfer.files;
        inputEl.dispatchEvent(new Event('change'));
    }
    
    return {
        init
    };
};

export const initFormEvents = () => {
    dragAndDropInputFields().init();
};