import {createApp} from 'vue';
import ToastNotifications from 'vue3-toastify';
import OnlyNumbersDirective from '@/shared/directives/only-numbers';
import('vue3-toastify/dist/index.css');

const init = (vueComponents) => {
    document.querySelectorAll('.swm-vue-component').forEach((element) => {
        const vueComponentName = element.dataset?.component || null;
        if (vueComponentName === null || !vueComponents[vueComponentName]) {
            return;
        }
        
        let componentProps = element.dataset?.props || null;
        componentProps = componentProps ? JSON.parse(componentProps) : null;
        
        createApp(vueComponents[vueComponentName], componentProps)
            .use(ToastNotifications, {
                autoClose: 3000,
                position: 'bottom-right',
                theme: 'colored',
            })
            .directive('only-numbers', OnlyNumbersDirective)
            .mount('#' + element.id);
    });
};

export default {
    init
};